const { getData, setData } = require('./database');
const UtilsDecode = require('./decode');
const Analytics = require('./analytics');

let setAccount = (data, token) => {
  if (typeof window !== 'undefined') {
    data.user = getUserSiglas(data.name);
    data.token = token;

    if (data.id) {
      Analytics.setUserId(UtilsDecode.decodeSingle(data.id));
    }

    setData('account', data);
    return true;
  }
  return false;
}

let getAccount = () => {
  if (typeof window !== 'undefined') {
    const data = getData('account', 999);
    if (data) {
      return data;
    }
  }
  return false;
}

let logout = () => {
  if (typeof window !== 'undefined') {
    setData('account', undefined);
  }
}

let getUserSiglas = (name) => {
  if (name) {
    let fullname = name.split(' ');
    let response = fullname[0].substring(0, 1);

    if (fullname.length === 1) {
      response = response + fullname[0].substring(1, 2);
    } else if (fullname.length > 1) {
      response = response + fullname[fullname.length-1].substring(0, 1);
    }
    return response.toUpperCase();
  }
  return '';
}

let setLastSeenAccount = () => {
  if (typeof window !== 'undefined') {
    setData('lastseen', { date: new Date() })
    return true;
  }
  return false;
}

let canSendLastSeenAccount = () => {
  if (typeof window !== 'undefined') {
    let data = getData('lastseen', 0.0834); // 5 minutes

    if (data) {
      return false;
    }
  }
  return true;
}

module.exports = { setAccount, getAccount, canSendLastSeenAccount, setLastSeenAccount, logout };
