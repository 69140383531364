let decode = (encoded) => {
  encoded = atob(encoded)
  var chunks = [];
  for (var index = 0, charsLength = encoded.length; index < charsLength; index += 6) {
    chunks.push(encoded.substring(index, index + 6).substring(1));
  }
  return JSON.parse(decodeURIComponent(escape(atob(chunks.join('')))));
}

let decodeSingle = (encoded) => {
  var chunks = [];
  for (var index = 0, charsLength = encoded.length; index < charsLength; index += 2) {
      chunks.push(encoded.substring(index, index + 2).substring(1));
  }
  return decodeURIComponent(escape(chunks.join('')));
}

module.exports = { 
  decode: decode,
  decodeSingle: decodeSingle
};

