import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../components/layout'
import Seo from '../../../components/seo';
import Modal from '../../../components/modal';
import Account from '../../../utils/account';
import Validations from '../../../utils/validations';
import Analytics from '../../../utils/analytics';
import Origin from '../../../utils/origin';
import Bootstrap from '../../../utils/bootstrap';
import axios from 'axios';
import $ from 'jquery';
import './styles/main.scss';

const DividendsMapPage = (location) => {
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState();
  
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [phone, setPhone] = useState('');
  let [ddd, setDdd] = useState('');
  let [patrimony, setPatrimony] = useState('');

  let [nameError, setNameError] = useState('');
  let [emailError, setEmailError] = useState('');
  let [phoneError, setPhoneError] = useState('');
  let [patrimonyError, setPatrimonyError] = useState('');

  const account = Account.getAccount() || undefined;

  const yearData = (new Date().getFullYear());
  const year = yearData < 2024 ? (yearData+1) : yearData;

  const ddds = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31,
    32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73,
    74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92,
    93, 94, 95, 96, 97, 98, 99];

  const patrimonyList = [
    {
      value: '10000',
      text: 'Até R$ 10 mil'
    },
    {
      value: '50000',
      text: 'Até R$ 50 mil'
    },
    {
      value: '100000',
      text: 'Até R$ 100 mil'
    },
    {
      value: '300000',
      text: 'Até R$ 300 mil'
    },
    {
      value: '1000000',
      text: 'Até R$ 1 milhão'
    },
    {
      value: '5000000',
      text: 'Até R$ 5 milhões'
    },
    {
      value: '5000001',
      text: 'Acima de R$ 5 milhões'
    },
    {
      value: '0',
      text: 'Não tenho valor para investir'
    },
  ];

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'name') {
      setNameError('');
      setName(value);
    } else if (name === 'email') {
      setEmailError('');
      setEmail(value);
    } else if (name === 'phone') {
      setPhoneError('');
      setPhone(value);
    } else if (name === 'ddd') {
      setPhoneError('');
      setDdd(value);
    } else if (name === 'patrimony') {
      setPatrimony(value);
      setPatrimonyError('');
    }
  };

  const goToLogin = event => {
    navigate('/conta/login/', { state: { lastPage: '/mapa-de-dividendos/' } });
  };

  const goToRegister = event => {
    navigate('/conta/cadastro/', { state: { lastPage: '/mapa-de-dividendos/' } });
  };

  const submitRequest = () => {
    setLoading(true);
    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: `https://api.analisedeacoes.com/account/dividendsmap/get`,
      data: {
        email: email,
        name: name,
        phone: phone ? `${ddd}${phone}` : undefined,
        patrimony,
        origin
      }
    })
    .then(result => {
      setLoading(false);

      if (account) {
        Analytics.track('Mapa de Dividendos', 'Download', 'E-mail enviado com sucesso (Usuário logado)');
      } else {
        Analytics.track('Mapa de Dividendos', 'Download', 'E-mail enviado com sucesso (Usuário não logado)');
      }

      const showForm = result.data.showForm;
      Bootstrap.hideModal('#dividendsmap-register');

      if (origin === 'eulb') {
        navigate('/mapa-de-dividendos/eulb/sucesso/', { state: { showForm, name, email, phone: `${ddd}${phone}`} });
      } else {
        navigate('/mapa-de-dividendos/sucesso/', { state: { showForm, name, email, phone: `${ddd}${phone}`} });
      }
    })
    .catch(() => {
      setLoading(false);
      Analytics.track('Mapa de Dividendos', 'Download', 'Provavelmente o e-mail não foi enviado');
      Bootstrap.hideModal('#dividendsmap-register');
      
      if (origin === 'eulb') {
        navigate('/mapa-de-dividendos/eulb/sucesso/', { state: { name, email, phone: `${ddd}${phone}`} });
      } else {
        navigate('/mapa-de-dividendos/sucesso/', { state: { name, email, phone: `${ddd}${phone}`} });
      }
    });

    // Bootstrap.hideModal('#dividendsmap-register');
    // const showForm = true;
    // navigate('/mapa-de-dividendos/eulb/sucesso/', { state: { showForm, name, email, phone: `${ddd}${phone}`} });
  };

  const handleClickDownload = event => {
    Analytics.track('Mapa de Dividendos', 'Modal lead', 'Abrir');
    Bootstrap.showModal('#dividendsmap-register');
  };

  const handleClickDownloadNotLogged = event => {
    let error = false;

    if (!(name.length > 3)) {
      setNameError('active');
      error = true;
    }

    if (!Validations.isValidEmail(email)) {
      setEmailError('active');
      error = true;
    }

    if (!Validations.isValidPhone(phone)) {
      setPhoneError('active');
      error = true;
    }

    if (!ddd) {
      setPhoneError('active');
      error = true;
    }

    if (!patrimony) {
      setPatrimonyError('active');
      error = true;
    }

    if (error) {
      return;
    }

    submitRequest();
  };

  const trackAnalytics = () => {
    Analytics.track('Mapa de Dividendos', `Modal lead`, `Fechar`, `#dividendsmap #dividendsmap-register .modal-header button`);
    Analytics.track('Mapa de Dividendos', `Modal lead`, `Continuar`, `#dividendsmap #dividendsmap-register .modal-footer .button-primary`);
  };

  useEffect(() => {
    $('#dividendsmap .phone-number').keypress(function(event) {
      return isNumber(event, this);
    });

    setOrigin(Origin.get());

    trackAnalytics();
  }, [])

  return (
    <Layout page="dividendsmap" location={location} name="mapadedividendos">
      <Seo
        title={`Mapa de Dividendos ${year}`}
        description="Saiba agora quando as ações costumam pagar dividendos, o mapa de dividendos do Análise de Ações é gratuito e informa em quais meses as empresas da bolsa pagam dividendos."
        image={'/images/helpers/cover-mapa-de-dividendos-analise-de-acoes.jpg'}
        url="/mapa-de-dividendos/" />

      <section className={'dividendsmap'}>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-xs-12'}>
              <h1 className={'title'}>Mapa de Dividendos {year}</h1>
              <p className={'subtitle'}>Descubra quando as empresas listadas na bolsa costumam pagar dividendos e planeje agora a sua renda passiva{ yearData === 2021 ? ' do ano que vem' : ''}.</p>
              <h2 className={'subtitle2'}>Programe sua fatia do lucro das melhores empresas de capital aberto da bolsa.</h2>
              <h2 className={'subtitle3'}>100% gratuito.</h2>
            </div>
          </div>

          <div className={'row'}>
            <div className={'col-xs-12 col-sm-12 col-md-6'}>
              <button type="button" className={"button button-primary button-download-top"} onClick={handleClickDownload} disabled={(account && loading)}>{ (account && loading) ? 'Carregando' : 'Baixar agora!'}</button>
              <img className="image" src="/images/helpers/mapa-de-dividendos-analise-de-acoes.jpg" height="399px" alt="Mapa de Dividendos" />
            </div>

            <div className={'col-xs-12 col-sm-12 col-md-6'}>
              <h2>Dados consistentes</h2>
              <p>Baseado no histórico de pagamento de dividendos dos últimos dez anos das principais empresas.</p>

              <h2>Facilidade</h2>
              <p>Todas as datas reunidas em um só lugar para você consultar como e quando quiser, sem pesquisar empresa por empresa.</p>

              <h2>Previsibilidade</h2>
              <p>Tenha uma visão ampla de cada detalhe e trace seus planos de renda passiva com mais segurança.</p>
            
              <button type="button" className={"button button-primary"} onClick={handleClickDownload} disabled={(account && loading)}>{ (account && loading) ? 'Carregando' : 'Baixar agora!'}</button>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              { /* eslint-disable-next-line jsx-a11y/heading-has-content */ }
              <h2></h2>
            </div>
          </div>
        </div>

        <Modal page={{ id: 'dividendsmap', name: 'Mapa de Dividendos'}} title="Não logado" id={`dividendsmap-notlogged`} small={true} content={{ title: 'Para baixar o mapa de dividendos, você precisa estar logado na sua conta.' }} actionPrimary={{ label: 'Criar uma conta gratuitamente', action: goToRegister }} actionSecondary={{ label: 'Já possuo uma conta', action: goToLogin }} />
        <Modal page={{ id: 'dividendsmap', name: 'Mapa de Dividendos'}} title="Sucesso" id={`dividendsmap-success`} small={true} content={{ title: 'Pronto! Em alguns minutos você vai receber o Mapa de Dividendos no seu e-mail, caso demore mais de 15 minutos verifique a sua caixa de spam :)' }} buttonClose={false} actionSecondary={{ label: 'Fechar' }} />
      
        <div id="dividendsmap-register" class="modal modal-small">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div class="modal-body">
                <p className="modal-title">Baixe agora seu Mapa de Dividendos {year}!</p>
                
                <div className={"row"}>
                  <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                    <input type="text" className={"form-control"} placeholder="Nome *" name="name" value={name} onChange={handleInputChange} maxLength={50} required />
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label className={`error ${nameError}`}>Verifique o nome digitado e tente novamente.</label>
                  </div>

                  <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                    <input type="text" className={"form-control"} placeholder="E-mail *" name="email" value={email} maxLength={80} onChange={handleInputChange} required />
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label className={`error ${emailError}`}>Verifique o e-mail digitado e tente novamente.</label>
                  </div>

                  <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                    <select value={ddd} onChange={handleInputChange} name="ddd" className="form-control phone-ddd">
                      <option value="">DDD *</option>
                      {
                        ddds.map((value, index) => (
                          <option key={index} value={value}>{value}</option>
                        ))
                      }
                    </select>
                    
                    <input type="tel" className={"form-control phone-number"} placeholder="Celular *" name="phone" value={phone} maxLength={9} onChange={handleInputChange} required />
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label className={`error ${phoneError}`}>Verifique o telefone digitado e tente novamente.</label>
                  </div>

                  <div className={"select-button input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                    { /* eslint-disable-next-line jsx-a11y/no-onchange */ }
                     <select value={patrimony} onChange={handleInputChange} name="patrimony" className="form-control patrimony">
                      <option value="">Patrimônio investido *</option>
                      {
                        patrimonyList.map((value, index) => (
                          <option key={index} value={value.value}>{value.text}</option>
                        ))
                      }
                    </select>
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label className={`error ${patrimonyError}`}>Selecione o patrimônio investido.</label>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="button button-primary" onClick={handleClickDownloadNotLogged} disabled={loading}>{`${loading ? 'Carregando' : 'Continuar'}`}</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

function isNumber(evt, element) {
  var charCode = evt.which;

  if ((charCode !== 45 || $(element).val().indexOf('-') === -1) &&
      (charCode !== 46 || $(element).val().indexOf('.') === -1) &&
      (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export default DividendsMapPage;
